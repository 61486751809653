.join_us{
    padding: 1.36rem 0 1.24rem;
}

.title{
    text-align: center;
    font-size: .24rem;
    font-weight: bolder;
}

.text{
    font-size: .14rem;
    text-align: center;
}


@media (max-width:768px) {
    .join_us{
        padding: 1rem 0 .8rem;
    }

    .title{
        font-size: .38rem;
    }
    
    .text{
        font-size: .22rem;
    }
}