.menu_footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0rem 1rem .5rem;
    background: #efefef;
    &>.nav_list>.nav_item{
        cursor: pointer;
        margin-top: .3rem;
        font-size: .14rem;
        color: #656565;
        .ic{
            width: .2rem;
            height: .2rem;
            margin-right: .2rem;
        }
    }
}