@media (max-height:768px) {
    .rj_header {
        position: relative;

        .ruijia_drawer_body {
            .rj_tree {
                margin-top: .32rem;

                &>.rj_tree_item {
                    margin-bottom: .2rem;
                    font-size: .18rem;
                }
            }

            .menu_footer {
                padding: 0rem .5rem 0.32rem;

                &>.nav_list>.nav_item {
                    margin-top: .24rem;
                    font-size: .13rem;

                    &>.ic {
                        width: .13rem;
                        height: .13rem;
                    }
                }
            }
        }
    }
}

@media (max-width:768px) {
    .rj_header {
        .ruijia_drawer_body {
            .rj_tree {
                margin-top: .64rem;
                padding: 0 1.04rem;

                &>.rj_tree_item {
                    margin-bottom: .5rem;
                    font-size: .26rem;

                    &>.icon {
                        width: .12rem;
                        height: .12rem;
                    }

                    &>.rj_tree {
                        height: 0rem;
                        min-height: 0;

                        &>.rj_tree_item {
                            font-size: .22rem;
                            margin-bottom: .4rem;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &:first-child {
                                margin-top: .4rem;
                            }
                        }

                        margin-top: 0;
                        padding: 0;
                    }

                    &.expand {
                        &>.rj_tree {
                            // min-height: 1.44rem;
                            height: auto;
                        }
                    }
                }
            }

            .menu_footer {
                padding: 0rem 1.48rem 0.64rem;

                &>.nav_list>.nav_item {
                    margin-top: .48rem;
                    font-size: .22rem;

                    &>.ic {
                        width: .24rem;
                        height: .24rem;
                        margin-right: .3rem;
                    }
                }
            }
        }
    }
}