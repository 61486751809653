.bread_cmp {
  display: flex;
  color: #333;
  align-items: center;
  font-size: 0.16rem;
  margin-bottom: 5px;
}

.ant_bread_cmp {
  font-size: .16rem;
}