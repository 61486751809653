.page_content_width_title{
    margin: .8rem auto 1.6rem;
    max-width: 10rem;
    &>.page_title{
        border-bottom: .02rem solid #e5e5e5;
        padding-left: 1rem;
        padding-bottom: .2rem;
        font-size: .26rem;
        font-weight: bolder;
    }
    &>.page_content{
        padding: 0 1rem;
    }
}



@media (max-width:768px){
    .page_content_width_title{
        margin: 0 auto 1rem;
        &>.page_title{
            padding: 0 .44rem;
            height: 1rem;
            line-height: 1rem;
            font-size: .32rem;
        }
        &>.page_content{
            padding: 0 .44rem;
        }
    }
}