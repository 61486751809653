.product_introdution{
    margin-top: 1.36rem;
    position: relative;
}
.product_introdution_img{
    float: unset;
    display: none;
}
.product_introdution_info{
    // height: 100%;
    position: relative;
    display: inline-block;
    float: unset;
    transform: translateY(-50%);
}
.product_introdution_content_block{
    align-items: flex-start;
    justify-content: center;
    margin-left: 19.767%;
    margin-right: 30.232%;
    vertical-align: middle;
}

.left,
.right{
    display: inline-block;
}

@media (max-width:768px) {
    .product_introdution{
        margin-top: .72rem;
    }
    .product_introdution_info{
        transform: translateY(0);
        width: 100%;
        padding: 0 .44rem .62rem;
    }
    .left{
        display: inline-block !important;
        width: 100%;
    }
    .product_introdution_content_block{
        align-items: center;
        margin: 0;

    }
    .right{
        display: none;
    }
}