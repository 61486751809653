.link_us {
    color: #656565;

    &>.link_form {
        margin-top: 64px;

        &>.form_label {
            margin-top: 34px;
            font-size: 14px;

            &.explanation {
                margin-top: 14px;
            }

            &>.xing {
                color: red;
            }
        }

        &>.form_widget {
            font-size: 12px;
            display: flex;

            &>.quhao {
                height: 34px;
                line-height: 34px;
                padding: 0 16px;
                margin-right: 10px;
                border: 1px solid #e5e5e5;
            }

            &>.input {
                border-bottom: 1px solid #e5e5e5;
                flex: 1;
                padding-bottom: 10px;
            }

            &.phone,
            &.area {
                margin-top: 14px;
            }

            &.name {
                margin-top: 24px;
            }
        }


        &>.submit_btn {
            margin-top: 52px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            background: #606060;
            border-radius: 3px;
            cursor: pointer;
        }


        &>.after_submit {
            margin-top: 24px;
            font-size: 14px;

            &>.prompt_list>li>.dot {
                display: inline-block;
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background: #606060;
                vertical-align: middle;
                margin-right: 5px;
            }
        }
    }

    &>.service_center {
        margin-top: 30px;
    }
}



@media (max-height:768px) {
    .link_us {
        &>.link_form {
            margin-top: 10px;

            &>.form_label {
                margin-top: 14px;
            }

            &>.form_widget {

                &.phone,
                &.area {
                    margin-top: 7px;
                }

                &.name {
                    margin-top: 14px;
                }
            }

            &>.submit_btn {
                margin-top: 20px;
            }

            &>.after_submit {
                margin-top: 10px;
            }
        }

        &>.service_center {
            bottom: .2rem;

            &>.service_time {
                margin-top: 10px;
            }
        }

    }
}


@media (max-width:768px) {
    .link_us {
        padding: 0 1.06rem;

        &>.link_form {
            margin-top: .84rem;

            &>.form_label {
                margin-top: .2rem;
                font-size: .28rem;

                &.explanation {
                    margin-top: .32rem;
                }

                &>.xing {
                    color: red;
                }
            }

            &>.form_widget {
                font-size: .22rem;
                display: flex;

                &>.quhao {
                    height: .5rem;
                    line-height: .5rem;
                    padding: 0 .2rem;
                    margin-right: .2rem;
                    border: .01rem solid #e5e5e5;
                }

                &>.input {
                    border-bottom: .01rem solid #e5e5e5;
                    flex: 1;
                    padding-bottom: .14rem;
                }

                &.phone,
                &.area {
                    margin-top: .2rem;
                }

                &.name {
                    margin-top: .2rem;
                }
            }


            &>.submit_btn {
                height: .56rem;
                line-height: .56rem;
                font-size: .22rem;
            }


            &>.after_submit {
                margin-top: .4rem;
                font-size: .28rem;

                &>.prompt_list {
                    margin-top: .1rem;
                    font-size: .22rem;
                }
            }
        }

        &>.service_center {
            position: absolute;
            bottom: 1rem;
        }
    }
}