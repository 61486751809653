.carousel-wrap {
    position: relative;
    margin-bottom: 1.36rem;

    .ant-carousel .slick-slide {
        height: auto !important;
    }

    &>.switch_btn {
        position: absolute;
        transform: translateY(-50%);
        width: .5rem;
        left: -.6rem;
        z-index: 1;
        cursor: pointer;

        &.next {
            left: auto;
            right: -.6rem;
        }
    }
}


@media (max-width:960px) {
    .carousel-wrap {
        &>.switch_btn {
            left: .2rem;
            width: .46rem;
            height: .46rem;

            &.next {
                left: auto;
                right: .2rem;
            }
        }
    }
}