
.video{
    width: 100%;
}


@media (max-width:768px){
    .video{
        height: 4.2rem;
    }
}