.video_introdution {
    position: relative;
    font-size: 0;

    &:hover {
        .play_icon {
            display: block;
        }
    }
}

.play_icon {
    width: 1.34rem;
    height: 1.34rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: none;
}

.product-introdution-wrap {
    margin-bottom: 1.36rem;
}

.brand-information-wrap {
    margin-bottom: 1.36rem;
}

.home-list>.ant-col {
    padding-bottom: 1.36rem;
}

@media (max-width:768px) {
    .play_icon {
        display: block;
    }

    .brand-information-wrap {
        margin-top: 0.72rem;
        margin-bottom: 1.32rem;
    }
}