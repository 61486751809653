.search_widget{
    display: flex;
    justify-content: center;
    font-size: 14px;
    &>.search_input{
        border-bottom: .03rem solid #606060;
        width: 3.6rem;
        color: #606060;
        padding-bottom: .1rem;
        &::placeholder{
            font-size: 14px;
            color: #606060;
        }
    }
    &>.search_btn{
        width: 1.34rem;
        height: .36rem;
        line-height: .36rem;
        text-align: center;
        color: #fff;
        background: #606060;
        cursor: pointer;
    }
}


@media (max-width:768px){
    .search_widget{
         width: 100%;
         &>.search_input{
            flex: 1;
            height: .48rem;
            font-size: .22rem;
            &::placeholder{
                text-indent: .1rem;
                font-size: .22rem;
                color: #606060;
            }
         }
         &>.search_btn{
            height: .48rem;
            line-height: .48rem;
            width: 1.8rem;
         }
    }
}