.service_center{
    &>.title{
        margin-bottom: 12px;
    }
    &>.hot_link{
        text-decoration: underline;
    }
    &>.service_time{
        display: inline-block;
        margin-top: 24px;
    }
}

@media (max-width:768px) {
    .service_center{
        font-size: .22rem;
        &>.title{
            font-size: .28rem;
            margin-bottom: .2rem;
        }

        &>.service_time{
            margin-top: .34rem;
        }
    }
}