.ruijia_drawer_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    visibility: hidden;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0,0,0,0);
        transition: all .5s;
    }

    .ruijia_drawer_header{
        display: flex;
        align-items: center;
        .close_icon{
            width: 16px;
            height: 16px;
            margin-right: 16px;
            cursor: pointer;
        }
        .title{
            margin-top: 0;
            font-size: 34px;
            color: #656565;
            font-weight: normal;
        }
    }

    .ruijia_drawer_content{
        position: absolute;
        top: 0;
        bottom:0;
        background: #fff;
        width: 512px;
        padding: 64px 100px 0;
        opacity: 1;
        z-index: 100;
        transition: all .5s;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 3px;
        }
        &.left{
            left: -512px;
        }
        &.right{
            right: -512px;
        }
    }
    &.open{
        visibility: visible;
        &::after{
            background: rgba(0,0,0,0.35);
        }
        .ruijia_drawer_content.left{
            left: 0;
        }
        .ruijia_drawer_content.right{
            right: 0;
        }
    }
}


@media (max-width:768px) {
    .ruijia_drawer_wrapper{
       
        .ruijia_drawer_header{
            .close_icon{
                width: .36rem;
                height: .36rem;
                margin-right: .7rem;
            }

            .title{
                font-size: .5rem;
            }
        }

        .ruijia_drawer_content{
            padding: .64rem .44rem;
            width: 100%;
        }
    }
}


@media (max-height:768px) {
    .ruijia_drawer_wrapper{

        .ruijia_drawer_content{
            width: 400px;
            padding: 32px 50px 0;
            &.left{
                left: -400px;
            }
            &.right{
                right: -400px;
            }
        }
    }
}