.rj_tree {
    margin-top: .4rem;

    &>.rj_tree_item {
        margin-bottom: .35rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        cursor: pointer;
        font-size: 18px;
        color: #656565;

        &>.icon1 {
            width: 8px;
            height: 8px;
            display: block;
        }

        &>.icon2 {
            width: 8px;
            height: 8px;
            display: none;
        }


        &>.rj_tree {
            overflow: hidden;
            min-height: 0;
            height: 0;
            width: 100%;
            margin-top: 0rem;
            margin-left: .2rem;
            transition: min-height .2s;

            &>.rj_tree_item {
                margin-bottom: .26rem;
                font-size: 14px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:first-child {
                    margin-top: .26rem;
                }
            }
        }

        &.expand {
            &>.icon1 {
                display: none;
            }

            &>.icon2 {
                display: block;
            }

            &>.rj_tree {
                //  min-height: 0.92rem;
                height: auto;
            }
        }
    }
}