.rj_check_box_grop {
    width: 100%;
    background: #f0f0f0;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    .checked_label {
        display: flex;
        align-items: center;
    }
}