.address_page{
    .amap-icon img{
        width: 25px;
    }
    .company_name{
        font-size: .18rem;
        font-weight: bold;
        margin-top: .34rem;
    }

    .address{
        margin-top: .2rem;
        color: #818181;
        font-size: .14rem;
    }


    .address_img{
        margin-top: .16rem;
        #map{
            width: 100%;
            height: 400px;
        }
    }

    .link_type{
        font-size: .18rem;
        font-weight: bold;
        margin-top: .5rem;
    }

    .phone_number{
        margin-top: .2rem;
        font-size: .14rem;
        color: #818181;
    }
}


@media (max-width:768px){
    .address_page{
        .company_name{
            font-size: .26rem;
            margin-top: .5rem;
        }
        .address{
            font-size: .22rem;
            margin-top: .3rem;
        }
        .address_img{
            margin-top: .34rem;
            #ifame{
                height: 6.99rem;
            }
        }

        .link_type{
            margin-top: .5rem;
            font-size: .26rem;
        }
        .phone_number{
            font-size: .22rem;
            margin-top: .24rem;
        }
    }
}