.product_item{
    padding: .2rem .2rem 1rem;
    &>.product_poster{
        width: 100%;
    }
    &>.product_info{
        width: 100%;
        text-align: center;
        &>.cate,
        &>.name{
            margin-top: .1rem;
        }
    }
}