:root {
  --primary-color: #606060;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: .2rem;
  background-color: #f5f5f5;
}


/* Handle */
::-webkit-scrollbar-thumb {
  width: .2rem;
  background: #000;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #000;
}

body {
  margin: 0;
  color: #000;
}

ul,
li,
p {
  margin: 0;
  padding: 0;
  list-style: none;
}

a{
  color: #000;
  &:hover{
    color: #000;
    text-decoration: none;
  }
}

input{
  border: none;
  outline: none;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.start {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.title {
  font-size: .18rem;
  font-weight: bolder;
  margin: 0;
  margin-top: 10px;
}

.text {
  font-size: .14rem;
  margin-top: .2rem;
}

.link {
  font-size: .17rem;
  margin-top: .2rem;
  padding-bottom: .05rem;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: .01rem;
    width: 100%;
    background: #000;
    transform: scale(1, 1);
  }
}

@keyframes underLine {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.link:hover::after {
  animation: underLine 1.5s linear infinite alternate;
}

.split-line {
  border-top: .01rem solid #e5e5e5;
}

.inpit-border {
  border-bottom: .01rem solid #dadada;
}

.btn {
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 0;
  border: .01rem solid #a4a4a4;

  &:focus {
    outline: none;
    outline-offset: 0;
    inset-inline: 0;
  }

  &:hover {
    color: unset;
  }

  &:active {
    box-shadow: none;
  }

  &:active:focus {
    outline: none;
  }
}

.primay_btn {
  background-color: var(--primary-color);
  color: #fff !important;
}


.ant-row{
  margin-left: 0 !important;
  margin-right: 0 !important;
}


@media (max-width:768px) {
  .title{
    margin-top: .44rem;
    font-size: .38rem;
  }
  .text{
    margin-top: .3rem;
    font-size: .22rem;
  }
  .link{
    margin-top: .3rem;
    font-size: .26rem;
  }
}