.footer {
    .submit_form {
        width: 6.6rem;
        margin: 0 auto;
        margin-top: .42rem;

        .form_item {
            padding: .14rem 0;
            margin-top: .32rem;
            border-bottom: .01rem solid #dadada;
            display: flex;
            align-items: center;

            .labels {
                margin-right: .3rem;
                margin-top: 0;

                &::after {
                    content: '*';
                    color: red;
                    display: inline-block;
                    margin-left: .1rem;
                }
            }

            .form_wiget {
                flex: 1;

                &.input {
                    border: none;
                    outline: none;
                }
            }
        }

        .btn_wrap {
            margin-top: .84rem;
        }

        .btn_wrap .btn {
            width: 3rem;
            color: #000;
        }
    }

    .footer_navs {
        padding: 1rem 1.8rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .link_icons {
            margin-top: 36px;

            &>.link_ic {
                width: 48px;
                height: 48px;
                margin-right: 30px;
                cursor: pointer;
            }
        }

        .qr_code {
            width: 1rem;
            height: 1rem;
        }
    }
}

@media (max-width:1200px) {
    .footer .footer_navs {
        padding: 1rem .5rem;
    }
}


@media (max-width:768px) {
    .footer {
        .submit_form {
            width: auto;
            margin: .34rem .44rem 0;
            font-size: .22rem;

            .form_item {
                margin-top: 0;
                padding: .49rem 0;

                .labels {
                    margin-right: .34rem;
                }
            }

            .btn_wrap {
                display: block;
                width: 100%;
                padding: 0 .9rem;
            }

            .btn_wrap .btn {
                display: block;
                height: .56rem;
                font-size: .24rem;
                width: 100%;

                &:first-of-type {
                    margin-bottom: .3rem;
                }
            }
        }

        .footer_navs {
            padding: .3rem 0;

            .link_icons>.link_ic {
                width: .68rem;
                height: .68rem;
                margin-right: .44rem;

                &.visible-xs {
                    display: inline-block !important;
                }
            }
        }
    }
}