.no_result{
    text-align: center;
    color: #606060;
    padding: 70px 0 100px;
}


@media (max-width:768px) {
    .no_result{
         font-size: .26rem;
         padding: 1.28rem 0 1.8rem;
    }
   
}