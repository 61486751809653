.search_bar_wraper {
    position: relative;
    user-select: none;

    .search_bar {
        border-bottom: .01rem solid #e5e5e5;
        border-top: .01rem solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: .54rem;

        .search_widget {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            .arrow_ic {
                width: .09rem;
                height: .06rem;
                margin-left: .1rem;
                transition: transform .2s linear;
            }


            &.left_search {
                font-size: .16rem;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: .1rem;
                    height: .09rem;
                    background-image: url('../../assets/img/bg_shaixuan.jpg');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    display: none;
                }

                &.expand {
                    .arrow_ic {
                        transform: rotate(180deg);
                    }
                }
            }

            &.right_search {
                .del_btn {
                    font-size: .14rem;
                    color: #878787;
                    text-decoration: underline;
                    margin-right: .2rem;
                }

                .split_line {
                    width: .01rem;
                    background-color: #5e5e5e;
                    height: .28rem;
                }

                &>.sort_widget {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 1.12rem;
                    padding-left: 0.1rem;
                    flex-wrap: nowrap;

                    &>.sort_label {
                        font-size: .12rem;

                        .label {
                            color: #3d3d3d;
                        }

                        .value {
                            margin-top: .06rem;
                            color: #878787;
                        }
                    }

                    &>.arrow_ic {
                        margin-left: .26rem;
                        transition: transform .2s linear;
                    }

                    &>.select_wrap {
                        width: 1.12rem;
                        position: absolute;
                        box-sizing: border-box;
                        right: 0;
                        height: 0;
                        bottom: 0rem;
                        overflow: hidden;
                        transition: all .3s linear;
                        border: .02rem solid transparent;
                        background: #fff;
                        z-index: 9;

                        &>.select_item {
                            font-size: 12px;
                            padding-left: .14rem;
                            height: .32rem;
                            line-height: .32rem;
                            cursor: pointer;

                            &.active {
                                color: #fff;
                                background: var(--primary-color);
                            }

                            &:hover {
                                background: rgba(0, 0, 0, .5);
                            }
                        }
                    }

                    &.expand {
                        &>.arrow_ic {
                            transform: rotate(180deg);
                        }

                        &>.select_wrap {
                            bottom: -.96rem;
                            height: .96rem;
                            border-color: #c7c7c7;
                        }
                    }
                }
            }
        }
    }

    .rj_check_box_grop {
        height: 0;
        transition: height .3s linear;
    }

    &.left_expand {
        .rj_check_box_grop {
            padding-top: .4rem;
            padding-bottom: .2rem;
            height: auto;
        }
    }

    &>.checked_options {
        padding: .2rem .22rem;
        display: flex;
        flex-wrap: wrap;

        &>.checked_item {
            display: inline-block;
            width: auto;
            height: .30rem;
            line-height: .28rem;
            border-radius: .21rem;
            padding: 0 .18rem;
            border: .01rem solid #bfbfbf;
            margin-right: .44rem;
            margin-bottom: .44rem;

            &>.del_ic {
                width: .10rem;
                height: .10rem;
                margin-left: .12rem;
                cursor: pointer;
            }
        }
    }

}


@media (max-width:768px) {
    .search_bar_wraper {
        .search_bar {
            padding: 0 .44rem;
            height: .8rem;

            .search_widget {
                width: auto;

                &.left_search {
                    font-size: .22rem;
                }

                &.right_search {
                    .del_btn {
                        font-size: .22rem;
                        margin-right: .24rem;
                    }

                    .split_line {
                        height: .48rem;
                    }

                    &>.sort_widget {
                        width: auto;
                        padding-left: .24rem;

                        &>.sort_label {
                            font-size: .2rem;
                        }

                        &>.select_wrap {
                            width: 100%;

                            &>.select_item {
                                font-size: .22rem;
                                padding-left: .24rem;
                                height: .44rem;
                                line-height: .44rem;
                                cursor: pointer;

                                &.active {
                                    color: #fff;
                                    background: var(--primary-color);
                                }

                                &:hover {
                                    background: rgba(0, 0, 0, .5);
                                }
                            }
                        }

                        &.expand {
                            &>.arrow_ic {
                                transform: rotate(180deg);
                            }

                            &>.select_wrap {
                                bottom: -1.32rem;
                                height: 1.32rem;
                                border-color: #c7c7c7;
                            }
                        }
                    }
                }
            }
        }

        .rj_check_box_grop {
            padding: 0 .44rem 0;
        }

        &.left_expand {
            .rj_check_box_grop {
                padding-top: .4rem;
                height: auto;
            }
        }

        &>.checked_options {
            padding: .4rem .44rem;
            display: flex;
            flex-wrap: wrap;

            &>.checked_item {
                display: inline-block;
                width: auto;
                height: .42rem;
                line-height: .4rem;
                border-radius: .21rem;
                font-size: .22rem;
                padding: 0 .18rem;
                border: .01rem solid #bfbfbf;
                margin-right: .44rem;
                margin-bottom: .44rem;

                &>.del_ic {
                    width: .12rem;
                    height: .12rem;
                    margin-left: .12rem;
                }
            }
        }
    }
}