.question_item{
    margin-bottom: 54px;
    &>.title{
        margin-bottom: 20px;
    }
    &>.answer{
        font-size: 14px;
        color: #818181;
        line-height: 18px;
    }
}

@media (max-width:768px) {
    .question_item{
        margin-bottom: .6rem;
        &>.title{
            margin-bottom: .3rem;
        }
        &>.answer{
            font-size: .22rem;
            line-height: .28rem;
        }
    }
}