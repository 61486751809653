.need_help_content{
    margin-top: 64px;
    font-size: 14px;
    color: #656565;

    &>.service_center{
        margin-top: 90px;
    }

    &>.code_block{
        margin-top: 10px;
        .code_type{
            .ic{
                width: 24px;
                margin-right: 6px;
                height: 24px;
                vertical-align: bottom;
            }
        }

        .code_wrap{
            padding-left: 34px;
            margin-top: 10px;
            display: flex;
            &>.code_img{
                width: 128px;
                height: 128px;
                margin-right: 14px;
            }
        }

    }
}



@media (max-width:768px) {
    .need_help_content{
        margin-top: 1rem;
        padding-left: 1.06rem;
        padding-right: 1.06rem;
        font-size: .22rem;
        &>.service_center{
            margin-top: 1.4rem;
        }

        &>.code_block{
            margin-top: .18rem;
            .code_type{
                .ic{
                    width: .36rem;
                    height: .36rem;
                    margin-right: .04rem;
                }
            }
            .code_wrap{
                margin-top: .24rem;
                padding-left: .4rem;
                &>.code_img{
                    width: 1.92rem;
                    height: 1.92rem;
                    margin-right: .2rem;
                }
            }
        }
    }
}