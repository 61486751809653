@keyframes bounce {
    0%{
        transform: scale(1.4);
    }
    10%{
        transform: scale(1.1);
    }
    20%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}

.rj_alert{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    background: #000;
    border-radius: .05rem;
    width: 3.46rem;
    height: 1.64rem;
    padding: .3rem 0 .4rem;
    text-align: center;
    opacity: .5;
    transform: scale(0);
    transform-origin: center;
    transition: transform .5s;
    &.show{
        animation: bounce linear 1.5s forwards;
    }
    &>.alert_ic{
        width: .52rem;
        height: .52rem;
    }
    &>.alert_msg{
        color: #fff;
        margin-top: .2rem;
        font-size: .24rem;
        font-weight: bolder;
    }
}

