.header {
  height: 1.36rem;
}

.right_nav {
  width: 3.1rem;
  height: 100%;
  list-style: none;
}

.icon {
  width: 0.2rem;
  height: 0.2rem;
  cursor: pointer;
  border-radius: 50%;
}

.icon2 {
  width: 0.3rem;
  height: 0.3rem;
  cursor: pointer;
  border-radius: 50%;
}

.icon3 {
  width: 0.21rem;
  height: auto;
  cursor: pointer;
}

.logo {
  width: 2.2rem;
  height: 0.42rem;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .header {
    height: 1.14rem;
    padding: 0 0.5rem;
  }

  .icon {
    width: 0.4rem;
    height: 0.4rem;
    cursor: pointer;
  }

  .logo {
    width: 2.14rem;
    height: 0.4rem;
    position: unset;
    transform: translateX(0);
  }

  .right_nav {
    width: 1.5rem;
    height: 100%;
    list-style: none;
  }
}
