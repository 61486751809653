
.search_page{
    &>.search_area{
        overflow: hidden;
        padding-top: .7rem;
        margin: 0 0 0.7rem;
    }

    &>.search_result{
        min-height: 1rem;
        font-size: .18rem;
        color: #606060;
        &>.no_result{
            font-weight: bold;
            text-align: center;
        }

        &>.result_count{
            padding-bottom: .2rem;
        }
    }
}



@media (max-width:768px) {
    .search_page{
        &>.search_area{
            padding: .7rem .44rem 0;
            margin-bottom: 0;
        }

        &>.search_result{
            font-size: .26rem;
            padding: 0 .44rem;
            &>.no_result{
                margin-top: 1.28rem;
                padding-bottom: 1.8rem;
            }

            .result_count{
                margin-top: .6rem;
                font-size: .22rem;
            }
            .search_product_list{
                margin-top: .4rem;
            }
            .product_item{
                padding: 0;
                margin-bottom: 1rem;
            }
        }
    }
}