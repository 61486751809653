.case_page {
    &>.productions_shown {
        margin-top: 2.1rem;
        text-align: center;

        &>.title {
            font-size: .24rem;
        }

        &>.look_all {
            width: 1.3rem;
            height: .36rem;
            line-height: .36rem;
            text-align: center;
            font-size: .14rem;
            color: #fff;
            background-color: #606060;
            margin: .24rem auto;
            cursor: pointer;
        }

        &>.pic_wall {
            margin: .94rem 0 1.36rem;
            height: 5.4rem;
            padding-bottom: .2rem;
            overflow-x: auto;
            white-space: nowrap;
            display: flex;
            align-items: center;

            &::-webkit-scrollbar {
                height: .05rem;
                background: rgba(0, 0, 0, .1)
            }

            &>.pic_item {
                width: 4.88rem;
                height: 4.88rem;
                margin-right: .4rem;
                transform-origin: center center;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    transform: scale(1.12);
                }
            }
        }
    }
}

@media (max-width:768px) {
    .case_page {
        &>.productions_shown {
            margin-top: .8rem;
            padding: 0 .44rem;

            &>.title {
                font-size: .32rem;
            }

            &>.look_all {
                margin-top: .34rem;
                height: .46rem;
                line-height: .46rem;
                padding: 0 .16rem;
                display: inline-block;
                width: auto;
            }

            &>.pic_wall {
                height: 6.1rem;

                &::-webkit-scrollbar {
                    height: .06rem;
                    background: rgba(0, 0, 0, .1)
                }

                &>.pic_item {
                    width: 5.4rem;
                    height: 5.4rem;
                    margin-right: .45rem;
                }
            }
        }
    }
}