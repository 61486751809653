.city_selector {
    &>.select_widget {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: .02rem solid #dadada;
        height: .32rem;
        width: .8rem;
        line-height: .28rem;
        font-size: 14px;
        color: #606060;
        cursor: pointer;

        &>.name {
            display: inline-block;
            max-width: .6rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:first-of-type {
            margin-right: 20px;
        }

        &>.arrow_down {
            margin-left: .08rem;
            width: .06rem;
            height: .05rem;
        }

        &>.list_box {
            width: 1.22rem;
            height: 1.86rem;
            position: absolute;
            border: .01rem solid #000;
            background: #fff;
            top: .30rem;
            right: 0;
            overflow-y: auto;
            font-size: 12px;
            line-height: .38rem;
            text-align: left;
            text-indent: .12rem;
            color: #3f3f3f;
            user-select: none;
            z-index: 1;

            &::after {
                position: fixed;
                top: 0;
                left: 0;
                content: '';
                width: 100vw;
                height: 100vh;
                background: transparent;
                z-index: -1;
            }

            &::-webkit-scrollbar-thumb {
                width: .02rem;
                background: #e1e1e1;
            }

            &::-webkit-scrollbar {
                width: .02rem;
                background-color: #f5f5f5;
            }

            &.city_list {
                left: 0;
            }

            &>.list_item {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    background: #e1e1e1;
                }

                &.active {
                    background: var(--primary-color);
                    color: #fff;
                }
            }
        }
    }
}


@media (max-width:768px) {
    .city_selector {
        &>.select_widget {
            height: .52rem;
            width: 1.5rem;
            margin-top: .2rem;

            &>.name {
                max-width: .9rem;
            }

            &>.arrow_down {
                margin-left: .14rem;
                width: .09rem;
                height: .05rem;
            }

            &>.list_box {
                width: 1.66rem;
                height: 2.52rem;
                top: .5rem;
                text-indent: .18rem;

                &>.list_item {
                    font-size: .16rem;
                    margin-top: .24rem;
                }
            }
        }
    }
}