.rj_check_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .22rem .2rem;
    cursor: pointer;
    font-size: .16rem;

    &>.check_box_ic {
        margin-right: .06rem;
        width: .16rem;
        height: .16rem;
        vertical-align: middle;
        font-size: 0;
    }
}


@media (max-width:768px) {
    .rj_check_box {
        margin: 0 .25rem .2rem;

        &>.check_box_ic {
            margin-right: .08rem;
            width: .2rem;
            height: .2rem;
        }
    }
}