.brand_information {
  width: 100%;
}

.img_wraper {
  width: 100%;
  height: calc((100vw - 200px) * 0.39);
  overflow: hidden;
}

.title {
  margin: .24rem 0;
  font-size: .24rem;
  font-weight: bolder;
  text-align: center;
}

.text_content {
  margin: 0 1rem;
}

@media (max-width:768px) {
  .title {
    margin-top: .44rem;
    font-size: .38rem;
  }

  .text_content {
    font-size: .22rem;
    margin: 0 .44rem;
  }

  .img_wraper {
    height: 41vw;
  }
}